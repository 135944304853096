<template>

    <textarea id="mytextarea">Hello, World!</textarea>
</template>

<script>


export default {
    name: "Test",
    methods: {
        uploadImg(blobInfo) {
            return new Promise((reslove, reject) => {
                let data = new FormData()
                data.append('file', blobInfo);
                // 注意 直接使用this.$http.post 它将会收到src/fetch/index.js 里面的配置影响。 如果你不需要， 就在这个页面执行下面的代码
                /**
                 * import axios from 'axios'
                 * let http = axios.create();
                 */
                this.$http.post('xxxx/uploadPath', data).then(res => {
                    if (res.xxx == true) { // 如果返回成功
                        reslove(res.data.url) // 把地址通过reslove传出去
                    } else {
                        reject('上传失败')
                    }
                }).catch(() => [
                     reject('上传失败')
                ])
            })
        }
    },
    mounted() {
        window.tinymce.init({
            selector: '#mytextarea',
            language:'zh-Hans',
            mobile: {
                menubar: false
            },
            plugins: [
                'image','insertdatetime'
            ],
            toolbar: 'image|forecolor|fontsize|undo redo',
            images_upload_handler: this.uploadImg,
        });
    }
}

</script>
<style lang="less">
textarea {
    height: 100vh;
};

</style>
